@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700;900&display=swap');

$primary_color: #24bca4;
$black: #000000;
$white: #ffffff;
$dark_grey: #656565;
$light_grey: #fbfbfb;
$width: 1170px;

@mixin sectionPadding {
  padding: 60px 20px;
}

@mixin sectionTitle {
  font-size: 4rem;
  font-weight: 700;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
}

html {
  font-family: 'Open Sans', sans-serif;
  font-size: 62.5%;
  position: relative;
  scroll-behavior: smooth;
  @media (max-width: 600px) {
    font-size: 50%;
  }
}

section:nth-child(even) {
  background-color: $light_grey;
}

@keyframes wheel {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(0, 20px);
  }
}

.header {
  padding: 5px 5px 20px 0;
  position: fixed;
  transition: all .3s ease-in-out;
  width: 100%;
  z-index: 100;
  &.active {
    background-color: rgba(255, 255, 255, .95);
    box-shadow: 0 0 5px 0 $dark_grey;
  }
  &__logo {
    height: 50px;
    width: 240px;
    transition: all .3s ease-in-out;
    &.active {
      filter: brightness(.1);
    }
    @media (max-width: 600px) {
      height: 40px;
      width: 192px;
    }
  }
  &__menu {
    align-items: center;
    display: flex;
    @media (max-width: 1250px) {
      height: 100%;
      position: fixed;
      width: 100%;
    }
  }
  
  &__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: $width;
  }
  &__hamburger {
    background-color: $white;
    height: 2px;
    margin: 20px;
    position: relative;
    top: 5px;
    transition: all .5s ease-in-out;
    width: 20px;
    z-index: 1000;
    &.active {
      background-color: $black;
      &::before, &::after {
        background-color: $black;
      }
    }
    &.open {
      background-color: transparent;
      transform: translateX(-50px);
    }
    &.open::before {
      transform: rotate(45deg) translate(35px, -35px);
    }
    &.open::after {
      transform: rotate(-45deg) translate(35px, 35px);
    }
    &::before, &::after {
      background-color: $white;
      content: '';
      height: 2px;
      position: absolute;
      transition: all .5s ease-in-out;
      width: 20px;
    }
    &::before {
      transform: translateY(-7px);
    }
    &::after {
      transform: translateY(7px);
    }
    @media (min-width: 1250px) {
      display: none;
    }
  }
  .menu {
    color: $white;
    display: flex;
    list-style: none;
    padding: 14px 0 0 0;
    transition: all .3s ease-in-out;
    &.active {
      color: $black;
    }
    @media (max-width: 1250px) {
      align-items: center;
      background-color: $white;
      bottom: 0;
      color: $black;
      //display: none;
      flex-direction: column;
      justify-content: space-evenly;
      margin: 0;
      padding: 14px 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: -1;
    }
    .active {
      color: $primary_color;
    }
    &__item {
      font-size: 1.6rem;
      font-weight: 500;
      margin-left: 40px;
      text-transform: uppercase;
      @media (max-width: 600px) {
        font-size: 2.4rem;
        margin: 0;
      }
    }
  }
}
.intro {
  align-items: center;
  display: flex;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  &__video {
    filter: brightness(.6);
    width: 100vw;
    @media (max-width: 1250px) {
      display: none;
    }
  }
  &__image {
    background-image: url(./img/mobilevideo.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100vw;
  }
  .mousescroll {
    border: 2px solid rgba(255, 255, 255, .9);
    border-radius: 13px;
    bottom: 15px;
    height: 40px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    width: 26px;
    z-index: 100;
    @media(max-width: 1250px) {
      display: none;
    }
    &__dot {
      animation: wheel 1.5s ease infinite;
      background-color: rgba(255, 255, 255, .9);
      border-radius: 50%;
      height: 6px;
      margin: 0 auto;
      transform: translate(0, 5px);
      width: 6px;
    }
  }
}
.quote {
  @include sectionPadding;
  margin: 0 auto;
  max-width: $width;
  &__author {
    font-size: 1.4rem;
    text-align: right;
  }
  &__blockquote {
    color: $dark_grey;
    margin: 0 auto;
    max-width: 945px;
  }
  &__quote {
    font-size: 1.4rem;
    font-style: italic;
    line-height: 2rem;
    text-align: center;
  }
  &__title {
    @include sectionTitle;
  }
}
.services {
  @include sectionPadding;
  background-color: $light_grey;
  text-align: center;
  &__button {
    background-color: $primary_color;
    border: none;
    color: $white;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 18px 30px;
    text-transform: uppercase;
  }
  &__description {
    color: $dark_grey;
    font-size: 1.4rem;
    line-height: 2rem;
  }
  &__icon {
    width: 50px;
  }
  &__ideas {
    font-size: 3.2rem;
    font-weight: 600;
    margin: 0 auto;
    max-width: $width;
    padding: 40px 0;
    text-align: center;
  }
  &__item {
    text-align: center;
  }
  &__list {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    list-style: none;
    margin: 0 auto;
    max-width: $width;
    padding: 20px;
    @media (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 720px) {
      grid-template-columns: 1fr;
    }
  }
  &__title {
    font-size: 2.2rem;
    text-transform: uppercase;
  }
}
.portfolio {
  @include sectionPadding;
  &__description {
    color: #111111;
    font-size: 2rem;
    line-height: 3rem;
    margin: 0 auto;
    max-width: $width;
    text-align: center;
  }
  &__title {
    @include sectionTitle;
  }
}
.about-us {
  @include sectionPadding;
  &__description {
    font-size: 2rem;
    line-height: 3rem;
    margin: 0 auto;
    max-width: $width;
    text-align: center;
  }
  &__image {
    background-image: url(./img/info/1.jpg);
    background-position: center;
    background-size: cover;
    height: 20vw;
    &--1 {
      background-image: url(./img/info/1.jpg);
    }
    &--2 {
      background-image: url(./img/info/2.jpg);
    }
    &--3 {
      background-image: url(./img/info/3.jpg);
    }
    &--4 {
      background-image: url(./img/info/4.jpg);
    }
    &--5 {
      background-image: url(./img/info/5.jpg);
    }
    &--6 {
      background-image: url(./img/info/6.jpg);
    }
    &--7 {
      background-image: url(./img/info/7.jpg);
    }
    &--8 {
      background-image: url(./img/info/8.jpg);
    }
    &--9 {
      background-image: url(./img/info/9.jpg);
    }
    &--10 {
      background-image: url(./img/info/10.jpg);
    }
    &--11 {
      background-image: url(./img/info/11.jpg);
    }
    &--12 {
      background-image: url(./img/info/12.jpg);
    }
    &--13 {
      background-image: url(./img/info/13.jpg);
    }
    &--14 {
      background-image: url(./img/info/14.jpg);
    }
    &--15 {
      background-image: url(./img/info/15.jpg);
    }
  }
  &__title {
    @include sectionTitle;
  }
  &__info {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-top: 40px;
  }
}
.clients {
  @include sectionPadding;
  &__description {
    font-size: 2rem;
    line-height: 3rem;
    margin: 0 auto;
    max-width: $width;
    padding: 0 0 40px 0;
    text-align: center;
  }
  &__image {
    width: 165px;
  }
  &__item {
    align-items: center;
    display: flex;
    opacity: .6;
    text-align: center;
    transition: all .2s ease-in;
    &:hover {
      opacity: 1;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style: none;
    margin: 0 auto;
    max-width: $width;
  }
  &__title {
    @include sectionTitle;
  }
}
.parallax {
  padding: 120px 20px;
  position: relative;
  text-align: center;
  &::before {
    content: '';
    background-image: url(./img/bg.jpg);
    background-size: cover;
    background-attachment: fixed;
    bottom: 0;
    filter: brightness(.6);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  &__button {
    background-color: transparent;
    border: 2px $white solid;
    color: $white;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 18px 30px;
    text-transform: uppercase;
  }
  &__title {
    @include sectionTitle;
    color: $white;
    font-size: 4rem;
    font-weight: 400;
    text-transform: none;
  }
}
.contact {
  @include sectionPadding;
  &__button {
    background-color: $primary_color;
    border: none;
    color: $white;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 18px 30px;
    text-transform: uppercase;
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 1250px) {
      grid-template-columns: 1fr;
    }
  }
  &__description {
    color: #111111;
    font-size: 2rem;
    line-height: 3rem;
    margin: 0 auto;
    max-width: 1170px;
    text-align: center;
  }
  &__details {
    padding: 0 20px;
    text-align: left;
  }
  &__form {
    @include sectionPadding;
    display: flex;
    flex-direction: column;
  }
  &__icon {
    height: 14px;
    padding-right: 10px;
  }
  &__input {
    border: 1px #dddddd solid;
    font-size: 1.4rem;
    margin: 0 0 20px 0;
    padding: 14px 18px;
    &--textarea {
      height: 80px;
      resize: none;
    }
  }
  &__text {
    align-items: center;
    display: flex;
    font-size: 1.4rem;
  }
  &__subtitle {
    @include sectionPadding;
    @include sectionTitle;
    font-size: 1.8rem;
    line-height: 2.7rem;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-left: 0;
    text-align: left;
    @media (max-width: 1250px) {
      padding-top: 20px;
    }
  }
  &__wrapper {  
    text-align: center;
    margin: 0 auto;
    max-width: $width;
  }
  &__title {
    @include sectionPadding;
    @include sectionTitle;
    line-height: 2.7rem;
  }
}
.map {
  height: 400px;
}
.footer {
  @include sectionPadding;
  background-color: #252525;
  color: $light_grey;
  font-size: 1.4rem;
  text-align: center;
  &__logo {
    height: 50px;
    width: 240px;
  }
}